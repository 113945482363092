import { configureStore } from "@reduxjs/toolkit";
import { generalApi } from "../services/generalApi";
import {transportApi} from '../services/transportApi'

export default configureStore({
    reducer: {
        [transportApi.reducerPath]: transportApi.reducer,
        [generalApi.reducerPath]: generalApi.reducer,

    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(transportApi.middleware, generalApi.middleware),
});