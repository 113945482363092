// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
//import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBNVZWPD_fhHMZcFCnJ3I3bAthVMGKHdAs",
  authDomain: "movelite-bfad3.firebaseapp.com",
  projectId: "movelite-bfad3",
  storageBucket: "movelite-bfad3.appspot.com",
  messagingSenderId: "699017245397",
  appId: "1:699017245397:web:19cbf1b7d47bade48f285c",
  measurementId: "G-FB5VP5QGRB"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
