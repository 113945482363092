import React from 'react';
import data from '../../data/home/services.json';

const Service = () => {
  return (
    <div id='homeservices' className='container'>
      <div className='d-flex flex-column flex-md-row col-lg-10 m-auto justify-content-between'>
        {data &&
          data.map((data) => {
            return (
              <div key={data.id} className='col-30'>
                <div className='cardex m-auto rounded py-3 my-3 px-2 px-md-3 d-flex flex-column'>
                  <img className='me-3 rounded-bottom' src={data.icon} alt='' />
                  <h3 className='fs-6 fw-bold'>{data.caption}</h3>
                  <hr className='w-25 icongreen' />
                  <p>{data.text}</p>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default Service;
