import React from 'react'
import HomeHero from '../components/Home/HomeHero'
import Service from '../components/Home/Service'
import '../components/Home/Home.scss'
import HomeServices from '../components/Home/HomeServices'
import TopRoutes from '../components/Home/TopRoutes'
import Testimonials from '../components/Home/Testimonials'
import Subscribe from '../components/Home/Subscribe'

const Home = ({ site, isFetching }) => {
  return ( 
    <div>
      <HomeHero />
      <HomeServices />
      <Service />
      <TopRoutes site={site}/>
      <Testimonials site={site} isFetching={isFetching} />
      <Subscribe />
    </div>    
  )
}

export default Home