import React, { useState } from 'react';
import bus from '../../assets/Bus.svg';
import { useSuscribeEmailMutation } from '../../services/generalApi';
import { toast } from 'react-toastify';

const Subscribe = () => {
  const [email, setEmail] = useState({'email': ''})
  const [emailError, setEmailError] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)

  const [ suscribeEmail ] = useSuscribeEmailMutation();

  const handleSubscribe = async (e) => {
    setSubmitLoading(true)
    e.preventDefault();
    await suscribeEmail(email).then((response) => {
      setSubmitLoading(false)
      setEmailError(false)
      const { data, error } = response
      if(data){
        toast.success('Suscription successful')
        setEmail({...email, 'email': ''})
      }else if(error){
        setEmailError(true)
      }else{
        setEmail({...email, 'email': ''})
        toast.error('Something went wrong')
      }
    })
  };
const handleEmailChange = (e) => {
  let { name, value } = e.target;
  setEmail({...email, [name]:value})
  
}
  return (
    <div style={{backgroundColor: "#44D7A8"}} className="">
        <h4 className='text-center pt-5 fs-3 fw-bold'>LET'S GET YOU SUBSCRIBED</h4>
    <div id='subscribe' className='container my-5 py-4 d-flex flex-column flex-sm-row justify-content-between align-items-center' >
      <div className='col-8 col-sm-6 col-md-5 mx-auto'>
        <img className='img-fluid' src={bus} alt='' />
      </div>
      <div className='col-10 col-sm-6 col-lg-6 mx-auto justify-content-between'>
        <h5 className='my-3'>Subscribe to get the latest news and updates</h5>
        <form onSubmit={handleSubscribe}>

          <input
            type='email'
            name='email'
            onChange={handleEmailChange}
            value={email?.email}
            placeholder='Your email address'
            className='border-1 w-100 py-2 py-md-3 px-3'
            required
            />{emailError && (<p style={{"color": "red", "fontSize": "11px", "fontWeight": "bold"}}>Invalid Email</p>)}
          {submitLoading ? (
            <button disabled={true} className='border-0 text-light fw-bold py-2 py-md-3 mt-3 px-3 px-md-5 bg-dark' >
              <span className="spinner-grow spinner-grow-sm" style={{ marginRight:"5px", color: "white" }} role="status" aria-hidden={true}></span>
              <span className="spinner-grow spinner-grow-sm" role="status" style={{ marginRight:"5px", color: "white" }} aria-hidden={true}></span>
              <span className="spinner-grow spinner-grow-sm" role="status" style={{ color: "white" }} aria-hidden={true}></span>
            </button>
          ) : (
            <button className='border-0 text-light fw-bold py-2 py-md-3 mt-3 px-3 px-md-5 bg-dark' >Subscribe</button>
          )}
           
        </form>
      </div>
    </div>
    </div>
  );
};

export default Subscribe;
