import React from 'react';
import '../components/AboutUs/About.scss';
import AboutHero from '../components/AboutUs/AboutHero';
// import ChooseUs from '../components/AboutUs/ChooseUs'
import OurCompany from '../components/AboutUs/OurCompany';

const About = ({ site }) => {
  return (
    <div id='About'>
      <AboutHero site={site} />
      <OurCompany site={site} />
      <p style={{opacity: 0, color: 'transparent'}}>Jaypee</p>
    </div>
  );
};

export default About;
