import React from 'react';
import speed from '../../assets/about/speed.png'
import overtaking from '../../assets/about/overtaking.png'
import second from '../../assets/second.jpg';
import misvis from '../../assets/misvis.jpg';
import road from '../../assets/about/road.png';
import traffic from '../../assets/about/traffic.png';
import inter from '../../assets/about-services/inter.png';
import intra from '../../assets/about-services/intra.png';
import courier from '../../assets/about-services/courier.png';
import airplane from '../../assets/about-services/airplane.png';
//import parse from 'html-react-parser';

const OurCompany = ({ site }) => {
  return (
    <div id='about'>
      <div className='my-4' id='whatwedo'>
        <div className='container col-md-10 col-lg-8 py-5 d-flex flex-column flex-md-row'>
          <div className='col-12 col-md-6'>
            <h3 className='mt-4'>What We Do</h3>
            <p>Experience a smooth ride in our modern buses. We work intensively
              in search of ideals that can add up in the lives of our customers.
              This is what moves us and we are grateful for the recognition.
              Established in Dec 2021, Movelite Limited has set out itself to be
              a leading transportation and Logistics organization with
              specialization in the movement of people and goods across several
              routes. Maintaining such an environment is a collaboration of
              staff and our customers. The outcome of our service delivery has
              transformed us into the most patronized transport business in the
              industry. We got you covered on all your intrastate and interstate
              Trips. Experience a smooth ride in our modern Vehicles.</p>
            {/* {parse(site?.general_detail?.what_we_do)} */}
          </div>
          <div className='col-12 col-md-6 px-md-5'>
            <img
              style={{ maxHeight: 500, width: '100%' }}
              src={second}
              alt=''
            />
          </div>
        </div>
      </div>
      <div className='container'>
        <div className='mb-5 d-flex justify-content-md-center flex-wrap col-md-10 mx-md-auto'>
          {/* {site?.services?.map((service) => (
            <div className='col-6 col-md-4 col-lg-3 px-3'>
              <img
                width={70}
                height={70}
                className='rounded'
                src={service.image}
                alt=''
              />
              <p className='mt-3 mb-0 fw-bold'>{service.name}</p>
              <p>{parse(service.description)}</p>
            </div>
          ))} */}
          <div className='col-6 col-md-4 col-lg-3 px-3'>
            <img
              width={70}
              height={70}
              className='rounded'
              src={intra}
              alt=''
            />
            <p className='mt-3 mb-0 fw-bold'>Intra State</p>
            <p>
              We allow our clients to travel from state to state within our
              country.
            </p>
          </div>
          <div className='col-6 col-md-4 col-lg-3 px-3'>
            <img
              width={70}
              height={70}
              className='rounded'
              src={inter}
              alt=''
            />
            <p className='mt-3 mb-0 fw-bold'>Inter State</p>
            <p>
              Clients can requests rides and get dropped off within lagos state.
            </p>
          </div>
          <div className='col-6 col-md-4 col-lg-3 px-3'>
            <img
              width={70}
              height={70}
              className='rounded'
              src={airplane}
              alt=''
            />
            <p className='mt-3 mb-0 fw-bold'>Airport</p>
            <p>
              We do our best to transport our clients to the airports against
              their flights.
            </p>
          </div>
          <div className='col-6 col-md-4 col-lg-3 px-3'>
            <img
              width={70}
              height={70}
              className='rounded'
              src={courier}
              alt=''
            />
            <p className='mt-3 mb-0 fw-bold'>Courier</p>
            <p>
              Drop a package with us and it'd be delivered to any where you
              consider.
            </p>
          </div>
          
        </div>
      </div>
      <div className='d-flex flex-column flex-md-row py-5 align-items-center'>
        <div className='ps-3 ps-md-5 col-12 col-md-6 pe-md-5'>
          <h4>Your safety is our priority</h4>
          {/* {parse(site?.general_detail?.safety_desc)} */}
           <p className='fs-5 mt-3'>
           The act of taking responsibility to ensure a person’s safety and
           well-being is referred to as Duty of Care. Our transportation
           service understands just how vital it is to be able to guarantee the
           safe transport of our customers at all times.
         </p>
         <p className='fs-5'>
           At Movelite, we truly believe that our passengers’ safety is our
           first priority, day and night.
         </p>
        </div>
        <div
          className='col-12 col-md-6 py-5 px-5'
          style={{ backgroundColor: '#44D7A8' }}>
          <div className='d-flex flex-wrap'>
            <div className='col-6 mx-auto px-2 px-md-4'>
              <img
                src={road}
                width={100}
                height={100}
                className='mb-5 rounded-circle'
                alt=''
              />
              <p>
                {" "}
              </p>
            </div>
            <div className='col-6 mx-auto px-2 px-md-4'>
              <img
                src={traffic}
                width={100}
                height={100}
                className='mb-5 rounded-circle'
                alt=''
              />
              <p>
                {" "}
              </p>
            </div>
            <div className='col-6 mx-auto px-2 px-md-4'>
              <img
                src={speed}
                width={100}
                height={100}
                className='mb-5 rounded-circle'
                alt=''
              />
              <p>
                {" "}
              </p>
            </div>
            <div className='col-6 mx-auto px-2 px-md-4'>
              <img
                src={overtaking}
                width={100}
                height={100}
                className='mb-5 rounded-circle'
                alt=''
              />
              <p>
                {" "}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='container'>
        <div className='d-flex flex-column flex-md-row justify-content-md-around align-items-md-center'>
          <div className='col-md-7 col-lg-6'>
            {/* <p className='fs-5'>{parse(site?.general_detail?.mission)}</p> */}
            <h4>Mission</h4>
            <p className='fs-5'>
              MOVELITE LIMITED wishes to maintain a work environment that
              fosters individual and professional growth for all employees
              thereby creating a "Great Place to Work" environment.
            </p>
            <h4 className='mt-md-5'>Vision</h4>
            {/* <p className='fs-5'>{parse(site?.general_detail?.vision)}</p> */}
            <p className='fs-5'>
              "When you think about your daily movement, think MOVELITE". Let’s
              work together and make road transport the most preferred in
              Nigeria.
            </p>
          </div>
          <div className='col-8 col-md-4'>
            <img className='img-fluid' src={misvis} alt='' />
          </div>
        </div>
        <div className='my-5'>
          <h4>Our Goals</h4>
          {/* {parse(site?.general_detail?.goals)} */}<p>
            We are a technology-driven organization that is working on using
            technology to get people from one location to another while
            steadfastly pursuing our ultimate objective of redefining mobility
            globally.
          </p>
          <p>
            Our main driving force is the desire to convey people more
            effectively. It motivates us to create and rethink mobility so that
            you can move freely and do things with ease.
          </p>

          <p>
            We are focused on human progress and advancement which enables us to
            create services that make commuting seamless and more convenient.
          </p>

          <p>
            You are the most significant person in our universe, and as a
            company, we have made it our life's work to continuously prioritize
            your happiness, safety, and advancement through the use of
            cutting-edge technology.
          </p>
        </div>
        {/* <div className='mb-5'>
          <h2 className='my-5 fs-1 text-center'>Meet Our Team</h2>
          <div className='d-flex flex-wrap'>
            <div className='col-6 col-md-4 pe-3  px-md-4'>
              <img
                className='mb-4'
                width={125}
                height={125}
                style={{ borderRadius: '50%' }}
                src={mission}
                alt=''
              />
              <h5>Oti Victory</h5>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore
                corrupti odio amet, ullam voluptatem accusantium, animi minus
                eaque, molestias minima consequatur. Provident suscipit quisquam
                dolores.
              </p>
            </div>
            <div className='col-6 col-md-4 pe-3  px-md-4'>
              <img
                className='mb-4'
                width={125}
                height={125}
                style={{ borderRadius: '50%' }}
                src={mission}
                alt=''
              />
              <h5>Chidinma Absa....</h5>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore
                corrupti odio amet, ullam voluptatem accusantium, animi minus
                eaque, molestias minima consequatur. Provident suscipit quisquam
                dolores.
              </p>
            </div>
            <div className='col-6 col-md-4 pe-3  px-md-4'>
              <img
                className='mb-4'
                width={125}
                height={125}
                style={{ borderRadius: '50%' }}
                src={mission}
                alt=''
              />
              <h5>Peter ....</h5>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore
                corrupti odio amet, ullam voluptatem accusantium, animi minus
                eaque, molestias minima consequatur. Provident suscipit quisquam
                dolores.
              </p>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default OurCompany;
