import { Swiper, SwiperSlide } from 'swiper/react';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { Pagination, Navigation } from 'swiper';
import "swiper/css/pagination";
import "swiper/css/navigation";
import './carousel.scss';
import 'swiper/css';
import data from '../../data/testimonials.json'

const Testimonials = ({ site, isFetching }) => {
 
  const width = window.screen.width

  return (
    <div>
      <h1 className='text-center fw-bold mt-2 mt-md-5'>Satisfied Travellers</h1>
      <div className='container my-5 d-flex flex-column justify-content-evenly flex-md-row'>
        <div id='swipper' className='col-12'>
          <Swiper
            slidesPerView={width <= 575 ? 1 : 2}
            spaceBetween={50}
            slidesPerGroup={width <= 575 ? 1 : 2}
            loop={false}
            loopFillGroupWithBlank={true}
            navigation={true}
            pagination={{
              dynamicBullets: true,
            }}
            modules={[Pagination, Navigation]}
            className='mySwiper'>
                {data.map((data) => {
              return (
                <SwiperSlide>
                  <div className='d-flex flex-column py-3 bg-light'>
                    <p className='px-3 pt-3'>{data.text}</p>
                    <div className='d-flex my-3 mx-3'>
                      <img
                        className='col-4 col-md-3 rounded-pill'
                        src={data.image}
                        alt=''
                      />
                      <div className='d-flex flex-column my-auto'>
                        <h4 className='ps-3 mt-2 mb-0'>{data.name}</h4>
                        <p className='ps-3'>
                          <FA className='pe-1' icon='fa-location-dot' />
                          {data.country}
                        </p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
