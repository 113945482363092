import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import '../styles/registration-form.scss';
import '../styles/regstyles.scss';

const BookedRideDetails = () => {
  const transactionData = JSON.parse(localStorage.getItem('transactionData'))
  const navigate = useNavigate()

  useEffect(() => {
    var td = localStorage.getItem("transactionData") 
    if(td === null || td === undefined){
        localStorage.clear()
        navigate('/')
    }
    
  }, [navigate])

  return (
    <div id='Registration'>
        <section className='form container'>
            <div className='wrapper'>
                <div className='title' style={{"fontSize": "27px"}}>Transaction Successful</div>
                
                <div className='form'>
                <div className='title'>Personal Details</div>
                <div className='inputfield'>
                    <label>Name</label>
                    <div className='custom_select'>
                    {transactionData?.name}
                    </div>
                </div>
                <div className='inputfield'>
                    <label>Email</label>
                    <div className='custom_select'>
                    {transactionData?.email}
                    </div>
                </div>
                <div className='inputfield'>
                    <label>Phone</label>
                    <div className='custom_select'>
                    {transactionData?.phone}
                    </div>
                </div>
                <div className='inputfield'>
                    <label>Address</label>
                    <div className='custom_select'>
                    {transactionData?.address}
                    </div>
                </div>

                <div className='title'>Student Details</div>
                <div className='inputfield'>
                    <label>Matric no.</label>
                    <div className='custom_select'>
                    {transactionData?.matric_no}
                    </div>
                </div>
                <div className='inputfield'>
                    <label>Hall</label>
                    <div className='custom_select'>
                    {transactionData?.hall}
                    </div>
                </div>
                <div className='inputfield'>
                    <label>Room no.</label>
                    <div className='custom_select'>
                    {transactionData?.room_no}
                    </div>
                </div>

                <div className='title'>Ride Details</div>
                <div className='inputfield'>
                    <label>Location</label>
                    <div className='custom_select'>
                    {transactionData?.ride?.from_place}
                    </div>
                </div>
                <div className='inputfield'>
                    <label>Destination</label>
                    <div className='custom_select'>
                    {transactionData?.ride?.to_place}
                    </div>
                </div>
                <div className='inputfield'>
                    <label>Bus Name</label>
                    <div className='custom_select'>
                    {transactionData?.ride?.bus_name}
                    </div>
                </div>
                <div className='inputfield'>
                    <label>Bus Location</label>
                    <div className='custom_select'>
                    {transactionData?.ride?.bus_location}
                    </div>
                </div>
                <div className='inputfield'>
                    <label>Drop Off</label>
                    <div className='custom_select'>
                    {transactionData?.ride?.drop_off}
                    </div>
                </div>
                <div className='inputfield'>
                    <label>Date Moving</label>
                    <div className='custom_select'>
                    {new Date(transactionData?.ride?.date_moving).toString()}
                    </div>
                </div>
                <div className='inputfield'>
                    <label>Persons</label>
                    <div className='custom_select'>
                    {transactionData?.persons}
                    </div>
                </div>
                <div className='inputfield'>
                    <label>Amount</label>
                    <div className='custom_select'>
                    {transactionData?.amount}
                    </div>
                </div>
                <div className='inputfield'>
                    <label>Date Of Transaction</label>
                    <div className='custom_select'>
                    {new Date(transactionData?.date_of_transaction).toString()}
                    </div>
                </div>
                <div className='inputfield mt-4'>
                    <button onClick={() => {localStorage.clear(); navigate('/');}} className='btn'>Go to Home</button>
                </div>
                </div>
            </div>
        </section>
    </div>
  );
};

export default BookedRideDetails;
