import React from 'react';
//import parse from 'html-react-parser';

const FAQs = ({ site }) => {
  return (
    <div id='FAQs' className='container-fluid'>
      <div className='faq-hero'></div>
      <div className='container d-flex my-5'>
        <div className='d-none pt-3 d-md-block col-md-5'>
          <h2>Have questions about us?</h2>
          <p>We are here to answer them</p>
          <h5 className='mt-5 mb-0'>Contact us anytime:</h5>
          {/* <p className='mb-0'>{site?.general_detail.email}</p>
          <p className='mb-5'>{site?.general_detail.phone}</p> */}
          <p className='mb-0'>movelitehq@gmail.com</p>
          <p className='mb-5'>+(234) 901-732-5223</p>
          <div className='mt-5 w-75 pt-5'>
            <h5>Still have questions?</h5>
            <p>
              Can't find the answers you need? Please chat to our friendly team.
            </p>
            <img src='' alt='' />
          </div>
        </div>
        <div className='col-12 col-sm-12 col-md-7'>
          <div className='accordion pb-2' id='accordionPanelsStayOpenExample'>
            {/* {site?.faqs?.map((faq, index) => (
              <div className="accordion-item">
                <h2 className="accordion-header" id={`panelsStayOpen-heading${index}`}>
                  <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#panelsStayOpen-collapse${index}`} aria-expanded="true" aria-controls={`panelsStayOpen-collapse${index}`}>
                    {faq.question}?
                  </button>
                </h2>
                <div id={`panelsStayOpen-collapse${index}`} className="accordion-collapse collapse show" aria-labelledby={`panelsStayOpen-heading${index}`}>
                  <div className="accordion-body">
                    {parse(faq.answer)}
                  </div>
                </div>
              </div> 

            ))} */}
            <div class='accordion-item'>
              <h2 class='accordion-header' id='panelsStayOpen-headingOne'>
                <button
                  className='accordion-button'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#panelsStayOpen-collapseOne'
                  aria-expanded='true'
                  aria-controls='panelsStayOpen-collapseOne'>
                  How do I book a ticket online with Movelite?
                </button>
              </h2>
              <div
                id='panelsStayOpen-collapseOne'
                class='accordion-collapse collapse show'
                aria-labelledby='panelsStayOpen-headingOne'>
                <div class='accordion-body'>
                  You can <strong>book</strong> online by typing{' '}
                  <code>https://www.movelite.ng/</code>
                </div>
              </div>
            </div>
            <div class='accordion-item'>
              <h2 class='accordion-header' id='panelsStayOpen-headingTwo'>
                <button
                  class='accordion-button collapsed'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#panelsStayOpen-collapseTwo'
                  aria-expanded='false'
                  aria-controls='panelsStayOpen-collapseTwo'>
                  Do you have a Mobile App?.
                </button>
              </h2>
              <div
                id='panelsStayOpen-collapseTwo'
                class='accordion-collapse collapse'
                aria-labelledby='panelsStayOpen-headingTwo'>
                <div class='accordion-body'>
                  <strong>Not yet</strong> but we are currently working on it.
                </div>
              </div>
            </div>
            <div class='accordion-item'>
              <h2 class='accordion-header' id='panelsStayOpen-headingThree'>
                <button
                  class='accordion-button collapsed'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#panelsStayOpen-collapseThree'
                  aria-expanded='false'
                  aria-controls='panelsStayOpen-collapseThree'>
                  Is there an Exact location for payment to be done?
                </button>
              </h2>
              <div
                id='panelsStayOpen-collapseThree'
                class='accordion-collapse collapse'
                aria-labelledby='panelsStayOpen-headingThree'>
                <div class='accordion-body'>
                  <strong>Yes</strong>, Either in <code>Student</code> Council
                  Office or <code>Cafe</code> 1bus.
                </div>
              </div>
            </div>
            <div class='accordion-item'>
              <h2 class='accordion-header' id='panelsStayOpen-headingFour'>
                <button
                  class='accordion-button collapsed'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#panelsStayOpen-collapseFour'
                  aria-expanded='false'
                  aria-controls='panelsStayOpen-collapseFour'>
                  How can payment be done?
                </button>
              </h2>
              <div
                id='panelsStayOpen-collapseFour'
                class='accordion-collapse collapse'
                aria-labelledby='panelsStayOpen-headingFour'>
                <div class='accordion-body'>
                  <strong>For now,</strong> there is no online payment Only{' '}
                  <code>POS</code> or <code>Cash</code> Payment is Available
                </div>
              </div>
            </div>
            <div class='accordion-item'>
              <h2 class='accordion-header' id='panelsStayOpen-headingFive'>
                <button
                  class='accordion-button collapsed'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#panelsStayOpen-collapseFive'
                  aria-expanded='false'
                  aria-controls='panelsStayOpen-collapseFive'>
                  How do I know my ticket payment is successful & valid?
                </button>
              </h2>
              <div
                id='panelsStayOpen-collapseFive'
                class='accordion-collapse collapse'
                aria-labelledby='panelsStayOpen-headingFive'>
                <div class='accordion-body'>
                  <strong>Once</strong> your payment has been confirmed by any
                  Movelite STAFF. A successful notification email will be sent
                  to you within <code>24hrs</code> with your <code>ticket</code>{' '}
                  Summary.
                </div>
              </div>
            </div>
            <div class='accordion-item'>
              <h2 class='accordion-header' id='panelsStayOpen-headingSix'>
                <button
                  class='accordion-button collapsed'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#panelsStayOpen-collapseSix'
                  aria-expanded='false'
                  aria-controls='panelsStayOpen-collapseSix'>
                  What luggage allowance are my entitled to?
                </button>
              </h2>
              <div
                id='panelsStayOpen-collapseSix'
                class='accordion-collapse collapse'
                aria-labelledby='panelsStayOpen-headingSix'>
                <div class='accordion-body'>
                  <strong>If</strong> you are using the <code>inter-state</code>{' '}
                  services you are entitled to <code>1 Big box</code> and{' '}
                  <code>1 medium hand luggage</code>. Should luggage exceed the
                  acceptable limit for <code>inter-state</code> services, you
                  will be required to use the <code>courier services</code> to
                  avoid inconveniencing the other students.This is critical to
                  maintain the service and safety and standards of{' '}
                  <strong>Movelite</strong>.
                </div>
              </div>
            </div>
            <div class='accordion-item'>
              <h2 class='accordion-header' id='panelsStayOpen-headingSeven'>
                <button
                  class='accordion-button collapsed'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#panelsStayOpen-collapseSeven'
                  aria-expanded='false'
                  aria-controls='panelsStayOpen-collapseSeven'>
                  Do all your Vehicles have AC unit?
                </button>
              </h2>
              <div
                id='panelsStayOpen-collapseSeven'
                class='accordion-collapse collapse'
                aria-labelledby='panelsStayOpen-headingSeven'>
                <div class='accordion-body'>
                  <strong>Yes</strong> all our vehicles have AC unit unless
                  stated otherwise at the <code>departure</code> point.
                </div>
              </div>
            </div>
            <div class='accordion-item'>
              <h2 class='accordion-header' id='panelsStayOpen-headingEight'>
                <button
                  class='accordion-button collapsed'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#panelsStayOpen-collapseEight'
                  aria-expanded='false'
                  aria-controls='panelsStayOpen-collapseEight'>
                  What if I miss my bus?
                </button>
              </h2>
              <div
                id='panelsStayOpen-collapseEight'
                class='accordion-collapse collapse'
                aria-labelledby='panelsStayOpen-headingEight'>
                <div class='accordion-body'>
                  <strong>You</strong> will have to reschedule for another trip
                  and your payment will be <code>forfeited</code>.
                </div>
              </div>
            </div>
            <div class='accordion-item'>
              <h2 class='accordion-header' id='panelsStayOpen-headingNine'>
                <button
                  class='accordion-button collapsed'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#panelsStayOpen-collapseNine'
                  aria-expanded='false'
                  aria-controls='panelsStayOpen-collapseNine'>
                  Can my departure date be changed?
                </button>
              </h2>
              <div
                id='panelsStayOpen-collapseNine'
                class='accordion-collapse collapse'
                aria-labelledby='panelsStayOpen-headingNine'>
                <div class='accordion-body'>
                  <strong>Customers</strong> are allowed to make changes to
                  there departure but it will have to be booked as a{' '}
                  <code>Personal Trip</code> which attracts extra charges which
                  paid must be paid before any change can be effected.
                </div>
              </div>
            </div>
          </div>
          <span className='disclaimer'>
            Disclaimer: <br />
            {/* <p className='pt-2'>{site?.general_detail?.disclaimer}</p> */}
            <p className='pt-2'>
              The information provided by MOVELITE ("we", "us" or "our") on
              movelite.ng (the "Site") is for general informational purposes
              only. All information on the Site is provided in good faith,
              however we make no representation or warranty of any kind, express
              or implied, regarding the accuracy, adequacy, validity,
              reliability, availablility, or completeness of any information on
              the Site. UNDER NO CIRCUMSTANCE SHALL WE HAVE ANY LIABILITY TO YOU
              FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE
              OF THE SITE OR RELIANCE ON ANY INFORMATION PROVIDED ON THE SITE.
              YOUR USE OF THE SITE AND YOUR RELIANCE ON ANY INFORMATION ON THE
              SITE IS SOLELY AT YOUR OWN RISK.
            </p>
          </span>
        </div>
      </div>
    </div>
  );
};

export default FAQs;
