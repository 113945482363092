import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { useGetPlacesDataQuery } from '../../services/transportApi';
import Loader from '../Loader/Loader';
import DatePicker from "react-datepicker";  
import "react-datepicker/dist/react-datepicker.css";

const HomeHero = () => {
  
  const { data: places, isFetching } = useGetPlacesDataQuery();
  
  const history = useNavigate();

  const [transportData, settransportData] = useState({
    from_place: "",
    to_place: "",
    date: new Date(),
  })

  const handleChange = (e) => {
    if(e?.target?.value === undefined || e?.target?.value === null) {
      // var newDateOptions = {
      //   year: "2-digit",
      //   month: "2-digit",
      //   day: "2-digit"
      // }
      let date_obj = (new Date(e)).toLocaleDateString("zh-Hans-CN")
      settransportData({...transportData, 'date':date_obj.replace(/\//g, '-')})
    } else {
      let { name, value } = e.target;
      settransportData({...transportData, [name]:value})
    }
    
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    localStorage.setItem("transport_data", JSON.stringify(transportData))
    history('/search-rides')
  };
  if(isFetching) return (
    <div style={{ height: '75vh' }} className="d-flex flex-column justify-content-center align-items-center">
     <Loader />
    </div>
  );

  return (
    <div id='homeHero'>
      <div style={{minHeight: '100vh'}} className='position-relative py-4 container d-flex flex-column-reverse flex-md-wrap flex-md-row pt-5 align-items-center justify-content-center'>
        <div className='col-12 col-md-6 d-flex justify-content-center'>
          <form
            onSubmit={handleSubmit}
            className='flex-column d-flex col-12 col-md-8'>
            <label className='mb-1 mt-2'>Travelling From</label>
            <select name='from_place' className=' py-2 py-md-3' value={transportData.from_place} onChange={handleChange} required>
              <option value=""></option>
              {places?.data?.filter((place) => !(place.name === transportData.to_place)).map((place, index) => (
                    <option key={index} value={place.name} className='py-1'>
                      {place.name}
                    </option>
              ))}
            </select>
            <label className='mb-1 mt-2'>Travelling To</label>
            <select name='to_place' className=' py-2 py-md-3' value={transportData.to_place} onChange={handleChange} required>
              <option value=""></option>
              {places?.data?.filter((place) => !(place.name === transportData.from_place)).map((place, index) => {
                  return (
                    <option key={index} value={place.name} className='py-1'>
                      {place.name}
                    </option>
                  );
                })}
            </select>
            <div className='d-flex justify-content-between'>
              <div className='w-100'>
                <label className='mb-1 mt-2'>Departure Date</label>
                <div className='w-100'>
                <DatePicker dateFormat="dd/MM/yyyy" value={transportData.date} onChange={handleChange} className="w-100 py-2 py-md-3" placeholderText='Enter Date' required />
                </div>
              </div>
            </div>
            <button type='submit' className='ms-0 mt-3 rounded w-100'>
              Proceed
            </button>
          </form>
        </div>
        <div className='col-12 col-md-6 d-flex flex-column'>
          <p className='text-light col-12 col-md-9'>
            <span className='fs-1 logoColor'>Hassle</span> freely, every time
            you commute within and outside your cities.
          </p>
          <p className='text-light col-12 col-md-9'>
            MOVELITE is a forward-thinking transportation and courier service
            provider focused on people, goods and convenient service delivery.
          </p>
        </div>
        <div
          style={{ top: '93%' }}
          className='position-absolute d-none d-md-block col-12  text-center'>
          <FA className='logoColor fa-bounce fa-2x' icon=' fa-chevron-down' />
        </div>
      </div>
    </div>
  );
};

export default HomeHero;
