import React, { useState } from 'react';
import './Contact.scss';
import { useSendMessageMutation } from '../../services/generalApi';
import { toast } from 'react-toastify';

const Contact = ({ site }) => {
  const [ sendMessage ] = useSendMessageMutation();

  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState({'email': '', 'phone': ''});

  const [contactData, setContactData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    message: ''
  });

  const handleSubmit = async (e) => {
    setIsLoading(true)
    e.preventDefault();
    setErr(false)
    await sendMessage(contactData).then((response) => {
      setIsLoading(false)
      let { data, error } = response;
      if(data){
        setContactData({
          first_name: '',
          last_name: '',
          email: '',
          phone: '',
          message: ''
        })
        toast.success('Message sent')
      }else if(error){
        console.log(error)
        if(error?.data?.message?.email?.includes('email')){
          setErr({ ...err, 'email': 'Invalid Email', 'phone': '' })
        }else if(error?.data?.message?.phone?.includes('phone')){
          setErr({ ...err, 'phone': 'Invalid Phone Number', 'email': '' })
        }
      }else{
        toast.error('Something went wrong')
      }

    })
  };
  
  const handleStateChange = (e) => {
    let { name, value } = e.target;
    setContactData({ ...contactData, [name]: value })
  }

  return (
    <div id='contact' className=''>
      <div className='mt-5 mb-3 container-md justify-content-between col-lg-8 d-flex flex-column flex-sm-row'>
        <div className='col-sm-5 col-lg-4 col-12'>
          <h2 className='mb-3'>Get in Touch</h2>
          <h4 className='mb-3'>Address</h4>
          {/* <p>{site?.general_detail.address}</p> */}
          <p>Lagos, Nigeria.</p>
          <h4 className='mb-3'>Email</h4>
          {/* <p>{site?.general_detail.email}</p> */}
          <p>movelitehq@gmail.com</p>
          <h4 className='mb-3'>Call</h4>
          {/* <p>{site?.general_detail.phone}</p> */}
          <p>+(234) 901-732-5223</p>
        </div>
        <div className='col-sm-7 col-md-5 col-md-6 col-12'>
          <form
            onSubmit={handleSubmit}
            className='d-flex px-2 px-md-3 py-3 my-3 flex-column'>
            <div className='d-flex flex-row justify-content-between'>
              <div className='d-flex flex-column w-100 me-2'>
                <label>First name</label>
                <input placeholder='John' name='first_name' value={contactData.first_name} onChange={handleStateChange} type='text' className='my-2 py-2' required />
              </div>
              <div className='d-flex flex-column w-100 ms-2'>
                <label>Last name</label>
                <input placeholder='Doe' name='last_name' value={contactData.last_name} onChange={handleStateChange} type='text' className='my-2 py-2' required />
              </div>
            </div>
            <label>Email Address</label>
            <input type='email' placeholder='johndoe@example.com' name='email' value={contactData.email} onChange={handleStateChange} className='my-2 py-2' required />
            {(err?.email?.length > 0) && (<p style={{"color": "red", "fontSize": "10px", "fontWeight": "bold"}}>{err?.email}</p>)}
            <label>Phone Number</label>
            <input type='text' name='phone' placeholder='e.g 09012345678' value={contactData.phone} onChange={handleStateChange} className='my-2 py-2' required />
            {(err?.phone?.length > 0) && (<p style={{"color": "red", "fontSize": "10px", "fontWeight": "bold"}}>{err.phone} <p>Use 11 digits without country code</p></p>)}
            <label>Message</label>
            <textarea
              name='message'
              className='my-2 py-2'pre-line
              placeholder='Your message...'
              required
              cols='30'
              value={contactData.message}
              onChange={handleStateChange} 
              rows='10'></textarea>
            
            {isLoading ? (
              <button
                disabled={true} 
                style={{ backgroundColor: "darkgrey" }}
                className='px-3 mt-2 rounded border py-1 ms-auto'>
                <span className="spinner-grow spinner-grow-sm" style={{ marginRight:"5px", color: "white" }} role="status" aria-hidden={true}></span>
                <span className="spinner-grow spinner-grow-sm" role="status" style={{ marginRight:"5px", color: "white" }} aria-hidden={true}></span>
                <span className="spinner-grow spinner-grow-sm" role="status" style={{ color: "white" }} aria-hidden={true}></span>
              </button>
              ) : (
              <button
                type='submit'
                className='px-3 mt-2 rounded border py-1 ms-auto'>
                Submit
              </button>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
