import { createApi } from '@reduxjs/toolkit/query/react';
import UrlService from './serviceUrl';
import axios from 'axios';

const axiosBaseQuery =
  ({ baseUrl } = { baseUrl: '' }) =>
  async ({ url, method, data, params }) => {
    try {
      const result = await axios({ url: baseUrl + url, method, data, params });
      return { data: result.data }
    } catch (axiosError) {
      let err = axiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
        
      };
    }
  };

export const transportApi = createApi({
  reducerPath: 'transportApi',
  baseQuery: axiosBaseQuery({ baseUrl: UrlService.BaseUrl }),
  endpoints: (builder) => ({
    getPlacesData: builder.query({
      query: () => ({ url: UrlService.Places, method: 'GET' }),
    }),
    getAllAvailableRides: builder.query({
      query: () => ({ url: UrlService.AllAvailableRides, method: 'GET' }),
    }),
    getSingleAvailableRide: builder.query({
      query: (rideId) => ({ url: `${UrlService.AllAvailableRides}${rideId}/`, method: 'GET' }),
    }),
    bookRide: builder.mutation({
      query: (body) => ({
        url: UrlService.Book,
        method: 'POST',
        data: body,
      }),
    }),
  }),
});

export const {
  useGetPlacesDataQuery,
  useGetAllAvailableRidesQuery,
  useGetSingleAvailableRideQuery,
  useBookRideMutation,
} = transportApi;

export const { endpoints, reducerPath, reducer } = transportApi;
