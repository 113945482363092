import React from 'react';
import logo from '../../assets/logo.png';
import './Footer.scss';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import {
  faLinkedin,
  faInstagram,
  faTelegram,
} from '@fortawesome/free-brands-svg-icons';

const Footer = ({ site }) => {
  return (
    <footer className='footer pt-3'>
      <div className='container'>
        <hr className='my-3 pt-1 rounded' />
        <div className='row mt-1 justify-content-between'>
          <div className='col-6 col-sm-6 col-lg-3'>
            <div className='logo-box'>
              <img className='img-fluid' src={logo} alt='logo' />
            </div>
          </div>
          <div className='col-12 col-sm-6 col-lg-3'>
            <strong>Contact Us</strong>
            <ul className='mt-3'>
              <li>
                Call:{' '}
                {site?.general_detail?.phone
                  ? site?.general_detail?.phone
                  : '+(234) 901-732-5223'}
              </li>
              <li>{site?.general_detail?.email}</li>
              <li>
                Address:{' '}
                {site?.general_detail?.address
                  ? site?.general_detail?.address
                  : 'Lagos, Nigeria'}
              </li>
            </ul>
          </div>
          <div className='col-12 col-sm-6 col-lg-3'>
            <strong>Terms </strong>
            <ul className='mt-3'>
              <li>Privacy Policy</li>
              <li>Terms Condition</li>
            </ul>
          </div>
          <div className='col-12 col-sm-6 col-lg-3'>
            <strong className=''>Connect with us:</strong>
            <ul className='ps-0 d-flex mt-3 list-unstyled'>
              <li
                className='bg-dark d-flex pb-0 justify-content-center align-items-center'
                style={{ borderRadius: '50%', height: 50, width: 50 }}>
                <a
                  href={
                    site?.general_detail?.linkedin
                      ? site?.general_detail?.linkedin
                      : 'https://www.linkedin.com/company/movelite-ng/'
                  }
                  className='py-1 px-1 py-md-2 px-md-2 '>
                  <FA className='mx-1 fa-1x' icon={faLinkedin} />
                </a>{' '}
              </li>
              <li
                className='mx-4 bg-dark pb-0 d-flex justify-content-center align-items-center'
                style={{ borderRadius: '50%', height: 50, width: 50 }}>
                <a
                  href='https://t.me/moveliteng'
                  className='py-1 px-1 py-md-2 px-md-2 '>
                  <FA className='mx-1 fa-1x' icon={faTelegram} />
                </a>{' '}
              </li>
              <li
                className='bg-dark pb-0 d-flex justify-content-center align-items-center'
                style={{ borderRadius: '50%', height: 50, width: 50 }}>
                <a
                  href='https://www.instagram.com/movelite.ng/'
                  className='py-1 px-1 py-md-2 px-md-2 '>
                  <FA className='mx-1 fa-1x' icon={faInstagram} />
                </a>{' '}
              </li>
            </ul>
          </div>
        </div>
        <p className='text-center'>
          {new Date().getFullYear()}&copy; <a href='/'>Movelite Team.</a>
          <br />
          All Rights Reserved. Movelite Team
          <br />
          <p style={{ display: 'none' }}>We love Jaypee</p>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
