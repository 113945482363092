import React from 'react'
import person1 from '../../assets/person1.png'
import person2 from '../../assets/person2.png'

const ContactHero = () => {
  return (
        
    <div id='contact-hero'>
        <div className='contact-hero text-center position-relative'>
            <h2>Contact Us</h2>
            <p>Feel free to lay your complaints. Let's get in touch 😉</p>
            <div className='firstpicture'>
                <img className='img-fluid rounded-pill' src={person1} alt="" />
            </div>
            <div className='secondpicture'>
                <img className='img-fluid rounded-pill' src={person2} alt="" />
            </div>
        </div>      
    </div>
  )
}

export default ContactHero