import React from 'react';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import topRoutes from '../../data/home/topRoutes.json';

const TopRoutes = ({ site }) => {
  return (
    <div id='TopRoutes' className='container my-3'>
      <h3 className='mb-1 mt-3 text-left'>Popular Destinations</h3>
      <p className='col-8 col-md-3 col-lg-2 py-3 mb-0'>
        Destinations our customers love to visit
      </p>
      {/* <button className='pt-1 pb-2 mt-2 mb-3 px-3 border-0 text-light rounded'>
        View more
      </button> */}
      <div id='image-slider'>
        {topRoutes.map((destination, index) => {
          return (
            <div key={index}>
              <div className='border sliderT position-relative'>
                <p className='position-absolute bg-light ms-1 px-2 py-1 mt-1 text-dark rounded fw-bold'>
                  Popular
                </p>
                <img src={destination.image} alt='' />
                <div className='mx-2 mt-3 mb-3'>
                  <h5 className='fw-bold'>{destination.name}</h5>
                  <div className='d-flex align-items-center'>   
                    <FA icon='fa-star' />                 
                    <FA icon='fa-star' />                 
                    <FA icon='fa-star' />                 
                    <FA icon='fa-star' />                 
                    <FA icon='fa-star' />    
                    <p className='mb-0 ms-2 fw-bold '>{destination.rating}.0</p>
                  </div>
                  <div className='mt-2 d-flex align-items-center'>
                    <FA icon='fa-location-dot' /> 
                    <p className='mb-0 ms-2'>{destination.location}</p>   
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TopRoutes;
