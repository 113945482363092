import React from 'react';

const HomeServices = () => {
  return (
    <div className='mt-5 mb-3 text-center col-10 col-md-6 mx-auto'>
      <h2 className='col-md-10'>
        Creating the best transport! experience you would never forget.
      </h2>
      <p className='text-muted'>
      Movelite is a technology-based mobility service provider that improves courier, logistics, and transportation services by making them more dependable, comfortable,safer,predictable, and exactly how you like it.
      </p>
    </div>
  );
};

export default HomeServices;
