import React, { useState, useEffect } from 'react';

import '../styles/registration-form.scss';
import '../styles/regstyles.scss';
import Loader from '../components/Loader/Loader';
import PaymentProcess from '../components/PaymentProcess';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  useGetSingleAvailableRideQuery,
  useBookRideMutation,
} from '../services/transportApi';

const BookRide = () => {
  const navigate = useNavigate();
  const {
    data: ride,
    isFetching,
    error,
  } = useGetSingleAvailableRideQuery(window.localStorage.getItem('ride_id'));
  const [paymentProcessNavigation, setPaymentProcessNavigation] =
    useState(false);
  const [paymentProcessLoading, setPaymentProcessLoading] = useState(false);
  const [bookingId, setBookingId] = useState('');
  const [phoneErr, setPhoneErr] = useState(false);
  const [bookRide] = useBookRideMutation();

  const [config, setConfig] = useState({});

  const [bookerData, setBookerData] = useState({
    ride_id:
      localStorage.getItem('ride_id') &&
      parseInt(localStorage.getItem('ride_id')),
    title: 'Mr',
    first_name: '',
    last_name: '',
    email: '',
    phone: '0',
    address: '',
    matric_no: '',
    hall: '',
    room_no: '',
    persons: 1,
    terms_and_condition: false,
  });

  useEffect(() => {
    if (bookerData?.ride_id === null || bookerData?.ride_id === undefined) {
      localStorage.clear();
      navigate('/');
    }
  }, [bookerData, navigate]);

  const handleSubmit = async (e) => {
    setPaymentProcessLoading(true);
    e.preventDefault();
    await bookRide(bookerData).then((response) => {
      setPaymentProcessLoading(false);
      setPhoneErr(false);
      
      const { data, error } = response;
      if (data) {
        const bookData = data.data;
        setBookingId(bookData?.id);
        setConfig({
          email: bookData?.email,
          amount: bookData?.amount * 100,
          reference: bookData?.tx_ref,
          currency: 'NGN',
          metadata: {
            name: bookData?.name,
            phone: bookData?.phone_number,
          },
          publicKey: bookData?.public_key,
          text: 'Pay Now',
        });
        setPaymentProcessNavigation(true);
      } else {
        if (error?.data?.message?.phone) {
          toast.error(error?.data?.message?.phone);
        } else {
          toast.error(Object.values(error?.data?.message)[0]);
        }
      }
    });
  };

  if (error) {
    if (error.status === 404) {
      //console.log("Something went wrong")
    } else if (error.status === 400) {
      //console.log(error.data)
    }
    navigate('/');
    localStorage.clear();
  }

  if (isFetching)
    return (
      <div
        style={{ height: '75vh' }}
        className='d-flex flex-column justify-content-center align-items-center'>
        <Loader />
      </div>
    );

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'terms_and_condition') {
      setBookerData({ ...bookerData, terms_and_condition: e.target.checked });
    } else if (name === 'persons') {
      setBookerData({ ...bookerData, persons: parseInt(value) });
    } else if (name === 'phone') {
      setBookerData({ ...bookerData, phone: value });
    } else {
      setBookerData({ ...bookerData, [name]: value });
    }
  };

  return (
    <div id='Registration'>
      <section className='form container'>
        <div className='wrapper'>
          {paymentProcessNavigation ? (
            <PaymentProcess
              setPaymentProcessNavigationChange={setPaymentProcessNavigation}
              config={config}
              bookingId={bookingId}
            />
          ) : (
            <>
              <div className='title'>Registration Form</div>
              <form onSubmit={handleSubmit} className='form'>
                <div className='inputfield'>
                  <label>Title</label>
                  <div className='custom_select'>
                    <select
                      onChange={handleChange}
                      value={bookerData.title}
                      className=''
                      name='title'
                      required>
                      <option value='Mr'>Mr</option>
                      <option value='Miss'>Miss</option>
                      <option value='Mrs'>Mrs</option>
                    </select>
                  </div>
                </div>
                <div className='inputfield'>
                  <label>First Name</label>
                  <input
                    onChange={handleChange}
                    value={bookerData.first_name}
                    name='first_name'
                    type='text'
                    className='input'
                    required
                  />
                </div>
                <div className='inputfield'>
                  <label>Last Name</label>
                  <input
                    onChange={handleChange}
                    value={bookerData.last_name}
                    name='last_name'
                    type='text'
                    className='input'
                    required
                  />
                </div>
                <div className='inputfield'>
                  <label>Matriculation Number</label>
                  <input
                    onChange={handleChange}
                    value={bookerData.matric_no}
                    name='matric_no'
                    type='text'
                    className='input'
                    required
                  />
                </div>
                <div className='inputfield'>
                  <label>Hall</label>
                  <input
                    onChange={handleChange}
                    value={bookerData.hall}
                    name='hall'
                    type='text'
                    className='input'
                    required
                  />
                </div>
                <div className='inputfield'>
                  <label>Room Number</label>
                  <input
                    type='text'
                    className='input'
                    onChange={handleChange}
                    value={bookerData.room_no}
                    name='room_no'
                    required
                  />
                </div>
                <div className='inputfield'>
                  <label>Person(s)</label>
                  <div className='custom_select'>
                    <select
                      onChange={handleChange}
                      value={bookerData.persons}
                      className=''
                      name='persons'
                      required>
                      {Array(ride?.available_seats)
                        .fill(0)
                        .map((_, i) => (
                          <option key={i} value={i + 1}>
                            {i + 1}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className='inputfield'>
                  <label>Email Address</label>
                  <input
                    onChange={handleChange}
                    value={bookerData.email}
                    type='email'
                    name='email'
                    placeholder='e.g jaypee@gmail.com'
                    className='input'
                    required
                  />
                </div>
                <div className='inputfield'>
                  <label>Phone Number</label>
                  <div style={{ width: '100%' }}>
                    <input
                      onChange={handleChange}
                      value={bookerData.phone}
                      placeholder='e.g 09012345678'
                      name='phone'
                      required
                      type='text'
                      className='input'
                    />
                    {phoneErr && (
                      <p
                        style={{
                          color: 'red',
                          fontSize: '10px',
                          whiteSpace: 'pre-line',
                        }}>
                        Invalid Phone Number (e.g 08012345678)
                      </p>
                    )}
                  </div>
                </div>
                <div className='inputfield'>
                  <label>Address</label>
                  <textarea
                    onChange={handleChange}
                    value={bookerData.address}
                    name='address'
                    required
                    className='textarea'></textarea>
                </div>
                <div className='inputfield terms'>
                  <label className='check'>
                    <input
                      onChange={handleChange}
                      value={bookerData.terms_and_condition}
                      name='terms_and_condition'
                      type='checkbox'
                      required
                    />
                    <span className='checkmark'></span>
                  </label>
                  <p className='mb-0'>Agreed to terms and conditions</p>
                </div>
                {paymentProcessLoading ? (
                  <div className='inputfield'>
                    <button
                      disabled={true}
                      className='btn'
                      style={{ backgroundColor: 'darkgrey' }}>
                      <span
                        className='spinner-grow spinner-grow-sm'
                        style={{ marginRight: '5px', color: 'white' }}
                        role='status'
                        aria-hidden={true}></span>
                      <span
                        className='spinner-grow spinner-grow-sm'
                        role='status'
                        style={{ marginRight: '5px', color: 'white' }}
                        aria-hidden={true}></span>
                      <span
                        className='spinner-grow spinner-grow-sm'
                        role='status'
                        style={{ color: 'white' }}
                        aria-hidden={true}></span>
                    </button>
                  </div>
                ) : (
                  <div className='inputfield'>
                    <button type='submit' className='btn'>
                      Proceed
                    </button>
                  </div>
                )}
              </form>
            </>
          )}
        </div>
      </section>
    </div>
  );
};

export default BookRide;
