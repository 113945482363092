import React from 'react'
import Contacts from '../components/Contact/Contact'
import ContactHero from '../components/Contact/ContactHero'
import ContactMap from '../components/Contact/ContactMap'

const Contact = ({ site }) => {
  return (
    <div>
      <ContactHero />
      <Contacts site={site}/>
      <ContactMap site={site}/>
    </div>
  )
}

export default Contact