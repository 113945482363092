import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

import UrlService from '../services/serviceUrl';
import Loader from '../components/Loader/Loader';
import bus from '../assets/bus.jpg';
import sienna from '../assets/sienna.jpg';
import { useNavigate } from 'react-router-dom';

const SearchRides = () => {
  const [ ridesData, setRidesData ] = useState({})
  const navigate = useNavigate()
  
  function BookNow (e) {
    e.preventDefault()
    localStorage.removeItem('transport_data')
    localStorage.setItem('ride_id', e.target.value)
    navigate('/book-a-ride')
  }
  //console.log(ridesData)

  useEffect(() => {
    var transport_data = JSON.parse(localStorage.getItem('transport_data'))
    if(transport_data === null || transport_data === undefined){
      navigate('/')
      localStorage.clear()
    }
    axios.post(UrlService.BaseUrl + UrlService.AvailableRides, transport_data).then((response) => {
      setRidesData(response.data)
    }).catch((error) => {
      toast.error(error.response.data || error.response.message)
      navigate('/')
      localStorage.clear()
    })
  }, [navigate, setRidesData]);

  if ( Object.keys(ridesData).length === 0)
    return (
      <div
        style={{ height: '75vh' }}
        className='d-flex flex-column justify-content-center align-items-center'>
        <Loader />
      </div>
    );
  
  function timeConverter(time){
    var hour = parseInt(time.slice(0, 2))
    var minutes = time.slice(-2)
    var new_time = `${String(hour).padStart(2, '0')}:${minutes}a.m`
    if (hour > 12){
        var new_hour = hour - 12
        new_time = `${String(new_hour).padStart(2, '0')}:${minutes}p.m`
    }
    return new_time
  }

  return (
    <div
      style={{ minHeight: '75vh', padding: '3rem 0' }}
      className='d-flex flex-column justify-content-center align-items-center'>

      {(ridesData?.message.includes("Unavailable")) ? (
        <>
          <p className='mb-0' style={{ fontSize: 200 }}>
          😔
          </p>
          <h2 className='text-center mb-0'>No available rides</h2>
          <p>Sorry, no available buses based on your search</p>
        </>
      ) : ridesData?.data?.same_place_related_rides.map((ride, index) => (
          <div key={index} className="w-100">
            <section className='depature mb-5 w-100' style={{ zIndex: 5 }}>
              <h4 className='text-center mb-2'>{`${ride.from_place} to ${ride.to_place} (${ride.bus_name})`}</h4>
              <div className='depature-trip-info pb-4'>
                <div className='depature-info'>
                  <figure>
                    <img src={ride.to_place !== "AIRPORT SHUTTLE" ? sienna : bus} alt='' />
                  </figure>
                </div>
                <div className='depature-info d-block text-center'>
                  <p className='mb-0 fw-bold'>Date:</p>
                  <p>
                    {' '}
                    <i className='fas fa-calendar-alt'></i>
                    {ride.date_moving.slice(0, 10)}
                  </p>
                </div>
                <div className='depature-info d-block text-center'>
                  <p className='mb-0 fw-bold'>Time of departure:</p>
                  <p>
                    {' '}
                    <i className='fas fa-clock'></i> {timeConverter(ride.date_moving.slice(11, 16))}
                  </p>
                </div>
                <div className='depature-info d-block text-center'>
                  <p className='mb-0 fw-bold'>Price:</p>
                  <p>₦{ride.current_price}</p>
                </div>
                <div className='depature-info'>
                  <button value={ride.ride_id} onClick={BookNow} type='Submit' className="border-0 rounded px-3 py-2 text-secondary" style={{backgroundColor: '#96ee0e'}}>Book now</button>
                </div>
              </div>
            </section>
          </div>

        ))}
      
      <div className='d-flex flex-column w-100 container align-items-start border-secondary pt-5' style={{borderTop: 2}} >
        <h2 className="mb-0 mt-5">{`Related Rides (By ${ridesData?.to_place !== "COVENANT UNIVERSITY" ? 'Destination' : 'Location' })`}</h2>
        {(ridesData?.same_place_related_rides?.filter((ride) => !ridesData?.data?.same_place_related_rides.map(d => d.ride_id)?.includes(ride.ride_id))?.length <= 0) ? (
          <p style={{"fontWeight": "bold"}}>{`No related rides based on ${ridesData?.to_place !== "COVENANT UNIVERSITY" ? 'destination' : 'location' } 😔`}</p>
        ) : ridesData?.same_place_related_rides?.filter((ride) => !ridesData?.data?.same_place_related_rides?.map(d => d.ride_id).includes(ride.ride_id)).map((ride, index) => (
          <div key={index} className="w-100">
            <section className='depature mb-5 w-100' style={{ zIndex: 5 }}>
              <h4 className='text-center mb-2'>{`${ride.from_place} to ${ride.to_place} (${ride.bus_name})`}</h4>
              <div className='depature-trip-info pb-4'>
                <div className='depature-info'>
                  <figure>
                    <img src={ride.to_place !== "AIRPORT SHUTTLE" ? sienna : bus} alt='' />
                  </figure>
                </div>
                <div className='depature-info d-block text-center'>
                  <p className='mb-0 fw-bold'>Date:</p>
                  <p>
                    {' '}
                    <i className='fas fa-calendar-alt'></i>
                    {ride.date_moving.slice(0, 10)}
                  </p>
                </div>
                <div className='depature-info d-block text-center'>
                  <p className='mb-0 fw-bold'>Time of departure:</p>
                  <p>
                    {' '}
                    <i className='fas fa-clock'></i> {timeConverter(ride.date_moving.slice(11, 16))}
                  </p>
                </div>
                <div className='depature-info d-block text-center'>
                  <p className='mb-0 fw-bold'>Price:</p>
                  <p>₦{ride.current_price}</p>
                </div>
                <div className='depature-info'>
                  <button value={ride.ride_id} onClick={BookNow} type='Submit' className="border-0 rounded px-3 py-2 text-secondary" style={{backgroundColor: '#96ee0e'}}>Book now</button>
                </div>
              </div>
            </section>
          </div>

        ))

          
        }
      </div>

      <div className='d-flex flex-column w-100 container align-items-start border-secondary pt-5' style={{borderTop: 2}} >
        <h2 className="mb-0 mt-5">Related Rides (By Week)</h2>
        {(ridesData?.data?.same_week_related_rides?.filter((ride) => !ridesData?.data?.same_week_related_rides?.map(d => d.ride_id).includes(ride.ride_id)).length <= 0) ? (
          <p style={{"fontWeight": "bold"}}>No related rides based on searched date week 😔</p>
        ) : ridesData?.same_week_related_rides?.filter((ride) => !ridesData?.data?.same_week_related_rides.map(d => d.ride_id).includes(ride.ride_id)).map((ride, index) => (
          <div key={index}>
            <section className='depature mb-5 w-100' style={{ zIndex: 5 }}>
              <h4 className='text-center mb-2'>{`${ride.from_place} to ${ride.to_place} (${ride.bus_name})`}</h4>
              <div className='depature-trip-info pb-4'>
                <div className='depature-info'>
                  <figure>
                    <img src={ride.to_place !== "AIRPORT SHUTTLE" ? sienna : bus} alt='' />
                  </figure>
                </div>
                <div className='depature-info d-block text-center'>
                  <p className='mb-0 fw-bold'>Date:</p>
                  <p>
                    {' '}
                    <i className='fas fa-calendar-alt'></i>
                    {ride.date_moving.slice(0, 10)}
                  </p>
                </div>
                <div className='depature-info d-block text-center'>
                  <p className='mb-0 fw-bold'>Time of departure:</p>
                  <p>
                    {' '}
                    <i className='fas fa-clock'></i> {timeConverter(ride.date_moving.slice(11, 16))}
                  </p>
                </div>
                <div className='depature-info d-block text-center'>
                  <p className='mb-0 fw-bold'>Price:</p>
                  <p>₦{ride.current_price}</p>
                </div>
                <div className='depature-info'>
                  <button type='Submit' value={ride.ride_id} onClick={BookNow} className="border-0 rounded px-3 py-2 text-secondary" style={{backgroundColor: '#96ee0e'}}>Book now</button>
                </div>
              </div>
            </section>
          </div>

        ))

          
        }
      </div>
      <button onClick={() => {navigate('/all-available-rides')}} className='border-0 rounded px-3 py-2 text-primary mt-4'>All Available Rides</button>
    </div>
  );
};

export default SearchRides;
