import React from 'react';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
//import parse from 'html-react-parser';

const AboutHero = ({ site }) => {
  return (
    <>
      <div
        id='AboutHero'
        className='d-flex flex-column align-items-center justify-content-center'>
        <h6 className='fs-1 text-light fs-1'>ABOUT US</h6>
        <div className='d-flex justify-content-center align-items-center'>
          <div className='line' />
          <p className='mb-0 mx-2'>
            <FA icon='fa-car' />
          </p>
          <div className='line' />
        </div>
        <p className='mb-0 fs-5 text-center'>
          We are providing transportation services
        </p>
      </div>
      <div id="whoweare" className='container d-flex my-5 flex-column flex-md-row '>
        <div className='d-flex col-12 col-md-10 mx-md-auto border border-2 rounded py-3 px-4 border-dark'>
        <p className='mb-0 fs-3 text-justify'>
            MOVELITE LIMITED is an innovative Transportation and Logistics
            Service Provider in Nigeria with concentration on people, goods and
            convenient service delivery. We got you covered on all your
            intrastate and interstate group journeys. 
          </p>
        </div>
      </div>
    </>
  );
};

export default AboutHero;
