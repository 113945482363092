import React from 'react';

import Loader from '../components/Loader/Loader';
import bus from '../assets/bus.jpg';

import { useGetAllAvailableRidesQuery } from '../services/transportApi';
import { useNavigate } from 'react-router-dom';

const AllAvailableRides = () => {
    const navigate = useNavigate()

    const { data: rides, isFetching } = useGetAllAvailableRidesQuery();
    function BookNow (e) {
        e.preventDefault()
        localStorage.removeItem('transport_data')
        localStorage.setItem('ride_id', e.target.value)
        navigate('/book-a-ride')
    }


    if (isFetching)
    return (
        <div
        style={{ height: '75vh' }}
        className='d-flex flex-column justify-content-center align-items-center'>
        <Loader />
        </div>
    );


    function timeConverter(time){
        var hour = parseInt(time.slice(0, 2))
        var minutes = time.slice(-2)
        var new_time = `${String(hour).padStart(2, '0')}:${minutes}a.m`
        if (hour > 12){
            var new_hour = hour - 12
            new_time = `${String(new_hour).padStart(2, '0')}:${minutes}p.m`
        }
        return new_time
    }
    return (
        <div
            style={{ minHeight: '75vh', padding: '3rem 0' }}
            className='d-flex flex-column justify-content-center align-items-center'>
            
            <div className='d-flex flex-column w-100 container align-items-center border-secondary pt-5' style={{borderTop: 2}} >
            <h2 className="mb-0 mt-5">ALL AVAILABLE RIDES</h2>
            {(rides.data?.length <= 0) ? (
                <>
                <p className='mb-0' style={{ fontSize: 200 }}>
                😔
                </p>
                <h2 className='text-center mb-0'>No available rides</h2>
                <p>Sorry, There are no available buses currently</p>
                </>
            ) : rides.data?.map((ride, index) => (
                <div key={index} className="w-100">
                    <section className='depature mb-5 w-100' style={{ zIndex: 5 }}>
                    <h4 className='text-center mb-2'>{`${ride.from_place} to ${ride.to_place} (${ride.bus_name})`}</h4>
                    <div className='depature-trip-info pb-4'>
                        <div className='depature-info'>
                            <figure>
                                <img src={bus} alt='' />
                            </figure>
                        </div>
                        <div className='depature-info d-block text-center'>
                            <p className='mb-0 fw-bold'>Date:</p>
                            <p>
                                {' '}
                                <i className='fas fa-calendar-alt'></i>
                                {ride.date_moving.slice(0, 10)}
                            </p>
                        </div>
                        <div className='depature-info d-block text-center'>
                            <p className='mb-0 fw-bold'>Time of departure:</p>
                            <p>
                                {' '}
                                <i className='fas fa-clock'></i> {timeConverter(ride.date_moving.slice(11, 16))}
                            </p>
                        </div>
                        <div className='depature-info d-block text-center'>
                            <p className='mb-0 fw-bold'>Price:</p>
                            <p>₦{ride.current_price}</p>
                        </div>
                        <div className='depature-info'>
                            <button type='Submit' value={ride.ride_id} onClick={BookNow} className="border-0 rounded px-3 py-2 text-secondary" style={{backgroundColor: '#96ee0e'}}>Book now</button>
                        </div>
                    </div>
                    </section>
                </div>

                ))}
            </div>

        </div>
    );
};

export default AllAvailableRides;
