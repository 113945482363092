import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import UrlService from '../services/serviceUrl';
import '../styles/registration-form.scss';
import '../styles/regstyles.scss';
import { usePaystackPayment } from 'react-paystack';
import axios from 'axios';

const PaymentProcess = ({ config, setPaymentProcessNavigationChange, bookingId }) => {
  const navigate = useNavigate()
  const initializePayment = usePaystackPayment(config);
  
  useEffect(() => {
    const onPaymentSuccess = (response) => {
      var payment_response = response
      axios.get(`${UrlService.BaseUrl}${UrlService.Verify}${payment_response?.reference}/`).then((response) => {              
        localStorage.clear()
        localStorage.setItem('transactionData', JSON.stringify(response?.data?.data))
        toast.success(response?.data?.message)
        navigate('/successful-booked-ride-details')
      }).catch((error) => {
        toast.error(error?.response?.data || error?.response?.message)
        localStorage.clear()
        navigate('/')
      });
    };
    const onPaymentClose = () => {
      axios.delete(`${UrlService.BaseUrl}${UrlService.CancelBooking}${bookingId}/`).then((response) => {
        setPaymentProcessNavigationChange(false);
        toast.warning('Transaction cancelled')
      }).catch((error) => {
        if (error.response?.status === 400){
          toast.error(error?.response?.data || error?.response?.message)
          localStorage.clear()
          navigate('/')
        }
      });
    }
    initializePayment(onPaymentSuccess, onPaymentClose)
    
  }, [initializePayment, setPaymentProcessNavigationChange, bookingId, navigate])
  return (
    <></>
  );
};

export default PaymentProcess;
