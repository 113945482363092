import React, { useEffect } from 'react';
import {Route, Routes } from 'react-router-dom';

import "./components/Fontawesome";
import Navbar from './components/Navbar/Navbar';
import Home from './routes/Home';
import About from './routes/About';
import Contact from './routes/Contact';
import FAQs from './routes/FAQs';
import Error from './routes/Error';
import Footer from "./components/Footer/Footer";
import BookRide from './routes/BookRide';
import SearchRides from './routes/SearchRides';
import AllAvailableRides from './routes/AllAvailableRides';
import { useGetSiteDetailsQuery } from './services/generalApi';
import Loader from './components/Loader/Loader';
import BookedRideDetails from './routes/BookedRideDetails';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactGA from 'react-ga';
import { getAnalytics } from 'firebase/analytics';
import { app } from './components/Firebase';


function App() {
  const { data: site, isFetching } = useGetSiteDetailsQuery();

    useEffect(() => { 
    ReactGA.initialize('G-FB5VP5QGRB');
    ReactGA.pageview(window.location.pathname + window.location.search);
    getAnalytics(app);

  }, [])

  if(isFetching) return (
    <div style={{ height: '75vh' }} className="d-flex flex-column justify-content-center align-items-center">
     <Loader />
    </div>
  )

  return (
    <div>
      <ToastContainer />
      <div className='App'>        
        <Navbar />
      </div>
      <Routes>    
        <Route exact path='/' element={<Home site={site} isFetching={isFetching} />}></Route>
        <Route exact path='/about' element={<About site={site} />}></Route>
        <Route exact path='/contact' element={<Contact site={site} />}></Route>        
        <Route exact path='/faq' element={<FAQs site={site} />}></Route>
        <Route exact path='/book-a-ride' element={<BookRide />}></Route>
        <Route exact path='/successful-booked-ride-details' element={<BookedRideDetails />}></Route>
        <Route exact path='/search-rides' element={<SearchRides />}></Route>
        <Route exact path='/all-available-rides' element={<AllAvailableRides />}></Route>
        <Route exact path='*' element={<Error />}></Route>
      </Routes>
      <div>
        <Footer site={site} />
      </div>
    </div>    
  );
}


export default App;
