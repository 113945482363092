const UrlService = {
  
  BaseUrl: 'https://movelite-api.vercel.app/api/v1',

  // General Info
  Details: '/general/site-details/',
  Subscribe: '/general/suscribe/',
  Message: '/general/send-message/',

  // Transport
  Places: '/transport/places/',
  AvailableRides: '/transport/available-rides/',
  AllAvailableRides: '/transport/available-rides/',
  SIngleAvailableRide: '/transport/available-rides/',
  Book: '/transport/book-ride/',
  CancelBooking: '/transport/booking-cancel/',
  Verify: '/transport/verify-payment/',
  };
  
  export default UrlService;
  
