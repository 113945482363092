import { createApi } from '@reduxjs/toolkit/query/react';
import UrlService from './serviceUrl';
import axios from 'axios';

const axiosBaseQuery =
  ({ baseUrl } = { baseUrl: '' }) =>
  async ({ url, method, data, params }) => {
    try {
      const result = await axios({ url: baseUrl + url, method, data, params });
      return { data: result.data }
    } catch (axiosError) {
      let err = axiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
        
      };
    }
  };

export const generalApi = createApi({
  reducerPath: 'generalApi',
  baseQuery: axiosBaseQuery({ baseUrl: UrlService.BaseUrl }),
  endpoints: (builder) => ({
    getSiteDetails: builder.query({
      query: () => ({ url: UrlService.Details, method: 'GET' }),
    }),
    suscribeEmail: builder.mutation({
      query: (body) => ({
        url: UrlService.Subscribe,
        method: 'POST',
        data: body,
      }),
    }),
    sendMessage: builder.mutation({
      query: (body) => ({
        url: UrlService.Message,
        method: 'POST',
        data: body,
      }),
    }),
  }),
});

export const {
  useGetSiteDetailsQuery,
  useSuscribeEmailMutation,
  useSendMessageMutation,
} = generalApi;

export const { endpoints, reducerPath, reducer } = generalApi;
