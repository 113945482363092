import React from 'react';

const ContactMap = ({ site }) => {
  return (
      <section className='location' data-aos='fade-up'>
        {/* <iframe            
          src={site?.general_detail?.map_location}
          title='1'
          className='mb-0'
          style={{borderTop: 2, width: '100%', height: 450}}
          allowfullscreen='true'
          loading='lazy'>
        </iframe> */}
        <iframe            
          src={'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9025.60975555513!2d3.1636772827697066!3d6.67034652550983!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b9ecc2b793f5d%3A0xedc612c32f1e8590!2sCovenant%20University!5e0!3m2!1sen!2sng!4v1636718370821!5m2!1sen!2sng'}
          title='1'
          className='mb-0'
          style={{borderTop: 2, width: '100%', height: 450}}
          allowfullscreen='true'
          loading='lazy'>
        </iframe>
      </section>
    
  );
};

export default ContactMap;
